import _ from "lodash";

const officePartitionTypes = [
  {
    name: "Reception",
    value: "Reception",
  },
  {
    name: "Office",
    value: "Office",
  },
  {
    name: "Open desk area",
    value: "Open desk area",
  },
  {
    name: "Conference room",
    value: "Conference Room",
  },
  {
    name: "Cafe",
    value: "Cafe",
  },
  {
    name: "Bathroom",
    value: "Bathroom",
  },
  {
    name: "Server room",
    value: "Server Room",
  },
  {
    name: "Storage",
    value: "Storage",
  },
];
const lifeSciencePartitionTypes = [
  { name: "Lab bench area", value: "Lab bench area" },
  {
    name: "Clean room",
    value: "Clean Room",
  },
  {
    name: "Vivarium",
    value: "Vivarium",
  },
];

export default {
  Residential: [
    {
      name: "Bedroom",
      value: "Bedroom",
    },
    {
      name: "Bathroom",
      value: "Bathroom",
    },
    {
      name: "Kitchen",
      value: "Kitchen",
    },
    {
      name: "Dining room",
      value: "Dining Room",
    },
    {
      name: "Living room",
      value: "Living Room",
    },
    {
      name: "Office",
      value: "Office",
    },
  ],
  Office: officePartitionTypes,
  Retail: [
    {
      name: "Office",
      value: "Office",
    },
    {
      name: "Bathroom",
      value: "Bathroom",
    },
    {
      name: "Server room",
      value: "Server Room",
    },
    {
      name: "Storage",
      value: "Storage",
    },
  ],
  Industrial: [
    {
      name: "Office",
      value: "Office",
    },
    {
      name: "Bathroom",
      value: "Bathroom",
    },
    {
      name: "Server room",
      value: "Server Room",
    },
  ],
  Hotel: [],
  Casino: [],
  "Research & Development": _.concat(
    lifeSciencePartitionTypes,
    officePartitionTypes,
  ),
  Healthcare: [],
  "Senior Housing": [],
  "Data Center": [],
  "Student Housing": [],
  "Self-Storage": [],
};
